var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LessonEntityDetails__Component" },
    [
      _c("FieldInlineText", {
        attrs: { readOnly: _vm.isEntityPage, label: "שם התרגול" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.name,
          callback: function($$v) {
            _vm.$set(_vm.model, "name", $$v)
          },
          expression: "model.name"
        }
      }),
      _c("FieldInlineText", {
        attrs: { readOnly: _vm.isEntityPage, type: "number", label: "סדר" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.sortOrder,
          callback: function($$v) {
            _vm.$set(_vm.model, "sortOrder", $$v)
          },
          expression: "model.sortOrder"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          readOnly: _vm.isEntityPage,
          label: "קטגוריה",
          filterable: true,
          clearable: true,
          optionsAsync: _vm.getCategories
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.categoryId,
          callback: function($$v) {
            _vm.$set(_vm.model, "categoryId", $$v)
          },
          expression: "model.categoryId"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          readOnly: _vm.isEntityPage,
          label: "סוג תרגול",
          filterable: true,
          clearable: true,
          options: [
            {
              Value: "0",
              Text: "רגיל"
            },
            {
              Value: 1,
              Text: "חוק מלא"
            },
            {
              Value: 2,
              Text: "חוק דינאמי"
            },
            {
              Value: 3,
              Text: "מיקודית"
            },
            {
              Value: 4,
              Text: "סימולציה"
            }
          ]
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.exerciseTypeId,
          callback: function($$v) {
            _vm.$set(_vm.model, "exerciseTypeId", $$v)
          },
          expression: "model.exerciseTypeId"
        }
      }),
      _c(
        "div",
        { staticClass: "checkbox-container" },
        [
          _c(
            "b-switch",
            {
              staticClass: "ltr",
              attrs: { disabled: _vm.isEntityPage },
              on: { input: _vm.onChange },
              model: {
                value: _vm.model.status,
                callback: function($$v) {
                  _vm.$set(_vm.model, "status", $$v)
                },
                expression: "model.status"
              }
            },
            [_vm._v(_vm._s(_vm.model.status ? "פעיל" : "לא פעיל"))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }